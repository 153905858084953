body {
  margin: 0;
}

a {
  cursor: pointer;
}

/* material-table */
tbody tr.MuiTableRow-root:not([index]) {
  /* display: none !important; */
}
/* material-table */
